<template>
  <div>
    <div class="table-operator">
      <a-row :gutter="24">
        <a-col :md="2" :sm="24">
          <a-button type="primary" icon="plus" @click="handleAdd">添加</a-button>
        </a-col>
      </a-row>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 140px">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible.sync="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/views/c-wms/counter_order_pay_method/modules/CreateForm'
import {
  shop_counter_order_pay_method,
  shop_counter_order_pay_method_create,
  shop_counter_order_pay_method_delete
} from '@/api/c_wms_wechat_counter_order'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      // 创建窗口控制
      visible: false,
      select_status_visible: false,
      select_edit_record: {},
      select_status_data: '',
      selectedRowKeys: [],
      selectedRows: [],
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '编码',
          dataIndex: 'code',
          ellipsis: true
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'action',
          width: '150',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return shop_counter_order_pay_method(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  methods: {
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleDelete (record) {
      shop_counter_order_pay_method_delete(record.id)
        .then((res) => {
          console.log(res)
          if (res.code === 1000) {
            this.$refs.table.refresh()
          }
        })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.order_nos = this.selectedRows.map(item => {
            return item.order_no
          })
          shop_counter_order_pay_method_create(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).catch((err) => {
            console.log(err)
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    }
  }
}
</script>
